$accent: #20bec6;
@import "variables";

@import "font";
@import "buttons";
@import "form";

@import "header";
@import "menu";
@import "logo";
@import "main";
@import "post";
@import "pagination";
@import "footer";


@import "prism";
@import "syntax";
@import "code";
@import "terms";
@import "gist";


/* Container for all logos */
.client-logos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr)); /* Adjusted this line */
    gap: 20px;
}


/* Individual client logo styling */
.client-logo img {
    max-width: 100%;
    height: auto;
}
